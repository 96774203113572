import React, { useState } from 'react';
import Button from './Button';
import Display from './Display';

const Calculator = () => {
  const [display, setDisplay] = useState('');
  const [result, setResult] = useState(0);

  const handleClick = (value) => {
    if (value === '=') {
      try {
        setResult(eval(display));
      } catch (error) {
        setResult('Error');
      }
    } else if (value === 'C') {
      setDisplay('');
      setResult(0);
    } else {
      setDisplay(display + value);
    }
  };

  return (
    <div className="calculator">
      <Display display={display} result={result} />
      <div className="buttons">
        {['7', '8', '9', '/', '4', '5', '6', '*', '1', '2', '3', '-', '0', '.', '=', '+', 'C'].map((item, index) => (
          <Button key={index} value={item} onClick={handleClick} />
        ))}
      </div>
    </div>
  );
};

export default Calculator;
