import React from 'react';

const Display = ({ display, result }) => {
  return (
    <div className="display">
      <div className="input">{display}</div>
      <div className="result">{result}</div>
    </div>
  );
};

export default Display;
